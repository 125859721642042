//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "../../../../common/ResponsiveImage";
import ClothesTable from "./ClothesTable.vue";
import TrousersTable from "./TrousersTable.vue";

export default {
    name: "MansTable",
    props: {
        sizeChart: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
            clothesImage: StaticImages.manTop,
            trousersImage: StaticImages.manBottom,
        }
    },
    computed: {
        isManClothes() {
            return this.sizeChart === "size_tops_man";
        },
        isManSwimming() {
            return this.sizeChart === "size_swimming_man";
        },
        isManTrousers() {
            return this.sizeChart === "size_bottoms_man";
        },
        tableTitle() {
            return this.isManClothes ? this.$t('sizeTable.manClothes.title') :
                        this.$t('sizeTable.manTrousers.title');
        },
        sizeTableClass() {
            return this.isManClothes ? `${this.mainClass}__clothes` :
                        `${this.mainClass}__trousers`;
        },
        imageSrc() {
            return this.isManClothes ? this.clothesImage : this.trousersImage;
        }
    },
    methods: {
        imageConfig(url, title = "", alt = "") {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            }
        },
    },
    components: {ResponsiveImage, ClothesTable, TrousersTable},

}
