//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ShoesTable from "./ShoesTable.vue";
import ResponsiveImage from "../../../../common/ResponsiveImage";

export default {
    name: "ShoesSizeTable",
    props: {
    },
    data: function() {
        return {
            isInch: false,
            shoesImage: StaticImages.sizeTableShoesBlock,
        }
    },
    computed: {
        imageSrc() {
            return this.isWomenSwimsuits ? this.swimsuitsImage : this.clothesImage;
        },
    },
    methods: {
        scrollTo(el) {
            return {
                el: el,
                container: '.v-popup__container__body',
                duration: 300,
                lazy: false,
                easing: 'linear',
                cancelable: true,
                x: false,
                y: true
            }
        },
        imageConfig(url, title = "", alt = "") {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            }
        },
    },
    components: {ResponsiveImage, ShoesTable},

}
