//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "../../../../common/ResponsiveImage";
import SummerShoesTable from "./SummerShoesTable.vue";

export default {
    name: "SummerShoes",

    data: function() {
        return {
            summerShoesImage: StaticImages.summerShoes,
        }
    },
    computed: {
        tableTitle() {
            return this.$t('sizeTable.summerShoes.title');
        },
        sizeTableClass() {
            return `${this.mainClass}__summer-shoes`;
        },
        imageSrc() {
            return this.summerShoesImage;
        }
    },
    methods: {
        imageConfig(url, title = "", alt = "") {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            }
        },
    },
    components: {ResponsiveImage, SummerShoesTable},

}
