//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VPopup from "~/plugins/VPopup/popup";
import ShoesTable from "./components/ShoesTable/Index.vue";
import ChildrenTable from "./components/ChildrenTable/Index.vue";
import WomensTable from "./components/WomensTable/Index.vue";
import MansTable from "./components/MansTable/Index.vue";
import SummerShoes from "./components/SummerShoesTable/Index.vue";
import FootballBootsTable from "./components/FootballBootsTable/Index.vue";
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';
import SizeTrack from "~/components/popups/SizeTable/components/SizeTrackTable/Index.vue";
import SizeWTights from "~/components/popups/SizeTable/components/SizeWTightsTable/Index.vue";
import SizeBra from "~/components/popups/SizeTable/components/SizeBra/Index.vue";
import SizeUnitefitBottoms from "~/components/popups/SizeTable/components/SizeUnitefitBottoms/Index.vue";
import SizeUnitefitTops from "~/components/popups/SizeTable/components/SizeUnitefitTops/Index.vue";

export default {
    name: "SizeTable",
    props: {
        sizeChart: {
            type: String,
            default: ""
        }
    },
    components: {
        SizeUnitefitTops,
        SizeUnitefitBottoms,
        SizeTrack,
        SizeWTights,
        SizeBra,
        FootballBootsTable,
        VPopup,
        ShoesTable,
        WomensTable,
        ChildrenTable,
        MansTable,
        SummerShoes,
    },
    mixins: [
        breakpointsListMixin,
    ],
    data: function() {
        return {
            mainClass: "size-table__popup",
            childrenSizes: ["size-k_shoes", "size-ki_shoes", "size-kg_5-15y", "size-kb_5-16y", "size-ki_1-4y"]
        }
    },
    computed: {
        customStyle() {
            let style = {};

            if(this.mobileDown) {
                style = {
                    'maxHeight': window.innerHeight - 117 + "px"
                }
            }
            return style
        },
        isShoes() {
            return this.sizeChart === "size-shoes";
        },
        isChildrenSizeTable() {
            return this.childrenSizes.includes(this.sizeChart);
        },
        isWomensSizeTable() {
            return this.isWomenClothes || this.isWomenSwimsuits  || this.isWomenTrousers;
        },
        isMansSizeTable() {
            return this.isManClothes || this.isManTrousers || this.isManSwimming;
        },
        isWomenClothes() {
            return this.sizeChart === "size_tops_woman" || this.sizeChart === "size_tops_woman_dz" || this.sizeChart === "size_tops_woman_nc";
        },
        isManClothes() {
            return this.sizeChart === "size_tops_man";
        },
        isManSwimming() {
            return this.sizeChart === "size_swimming_man";
        },
        isManTrousers() {
            return this.sizeChart === "size_bottoms_man";
        },
        isWomenSwimsuits() {
            return this.sizeChart === "size_swimming_woman";
        },
        isWomenTrousers() {
            return this.sizeChart === "size_bottoms_woman";
        },
        isWTights() {
            return this.sizeChart === "size-w_tights";
        },
        isSummerShoes() {
            return this.sizeChart === "size-97" || this.sizeChart === "size-adult-slides";
        },
        isFootballBoots() {
            return this.sizeChart === 'size-football_boots_adults';
        },
        isSizeMTrack() {
            return this.sizeChart === 'size-m_track';
        },
        isBraAlpha() {
            return this.sizeChart === 'size-w_bra_alpha';
        },
        isSizeWTrack() {
            return this.sizeChart === 'size-w_track';
        },
        isSizeUnitefitBottoms() {
            return this.sizeChart === 'size-unitefit_bottoms';
        },
        isSizeUnitefitTops() {
            return this.sizeChart === 'size-unitefit_tops';
        },
        sizeTableClass() {
            return this.isShoes ? `${this.mainClass}__shoes` :
                this.isWomenSwimsuits ? `${this.mainClass}__swimsuits` :
                (this.isWomenClothes || this.isManClothes) ? `${this.mainClass}__clothes` :
                (this.isWomenTrousers || this.isManTrousers || this.isManSwimming) ? `${this.mainClass}__trousers` :
                this.isChildrenSizeTable ? `${this.mainClass}__children-shoes`:
                this.isSummerShoes ? `${this.mainClass}__summer-shoes` : "";
        },
    },
}
