//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "TrousersTable",
    data: function () {
        return {
            tableHeadData: [
                this.$t('sizeTable.womenTrousers.table.header.title1'),
                this.$t('sizeTable.womenTrousers.table.header.title2'),
                this.$t('sizeTable.womenTrousers.table.header.title3'),
                this.$t('sizeTable.womenTrousers.table.header.title4'),
                this.$t('sizeTable.womenTrousers.table.header.title5')
            ],
            tableBodyData: [
                [
                    "2XS",
                    "34 - 36",
                    "26 - 28",
                    "57 - 60",
                    "82 - 85"
                ],
                [
                    "XS",
                    "38 - 40",
                    "30 - 32",
                    "61 - 66",
                    "86 - 91"
                ],
                [
                    "S",
                    "42 - 44",
                    "34 - 36",
                    "67 - 72",
                    "92 - 97"
                ],
                [
                    "M",
                    "46 - 48",
                    "38 - 40",
                    "73 - 78",
                    "98 - 103"
                ],
                [
                    "L",
                    "50 - 52",
                    "42 - 44",
                    "79 - 85",
                    "104 - 110"
                ],
                [
                    "XL",
                    "54 - 56",
                    "46 - 48",
                    "86 - 94",
                    "111 - 117"
                ],
                [
                    "2XL",
                    "58 - 60",
                    "50 - 52",
                    "95 - 104",
                    "118 - 125"
                ],
                [
                    "3XL",
                    "62 - 64",
                    "54 - 56",
                    "109 - 118",
                    "131 - 140"
                ],
                [
                    "4XL",
                    "66 - 68",
                    "58 - 60",
                    "119 - 128",
                    "141 - 150"
                ],
            ]
        }
    }
}
