//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "ClothesTable",
    props: {
        sizeChart: {
            type: String,
            default: ""
        }
    },
    data: function () {
        return {
            tableHeadData: [
                this.$t('sizeTable.womenClothes.table.header.title1'),
                this.$t('sizeTable.womenClothes.table.header.title2'),
                this.$t('sizeTable.womenClothes.table.header.title3'),
                this.$t('sizeTable.womenClothes.table.header.title4'),
                this.$t('sizeTable.womenClothes.table.header.title5'),
                this.$t('sizeTable.womenClothes.table.header.title6')
            ],
            tableBodyDataMain: [
                [
                    "XXS",
                    "36 - 38",
                    "26 - 28",
                    "73 - 76",
                    "57 - 60",
                    "82 - 85"
                ],
                [
                    "XS",
                    "40 - 42",
                    "30 - 32",
                    "77 - 82",
                    "61 - 66",
                    "86 - 91"
                ],
                [
                    "S",
                    "44 - 46",
                    "34 - 36",
                    "83 - 88",
                    "67 - 72",
                    "92 - 97"
                ],
                [
                    "M",
                    "46 - 48",
                    "38 - 40",
                    "89 - 94",
                    "73 - 78",
                    "98 - 103"
                ],
                [
                    "L",
                    "50 - 52",
                    "42 - 44",
                    "95 - 101",
                    "79 - 85",
                    "104 - 110"
                ],
                [
                    "XL",
                    "52 - 54",
                    "46 - 48",
                    "102 - 109",
                    "86 - 94",
                    "111 - 117"
                ],
                [
                    "XXL",
                    "56 - 58",
                    "50 - 52",
                    "110 - 118",
                    "94 - 104",
                    "118 - 125"
                ]
            ],
            tableBodyDataDZ: [
                [
                    "XXS",
                    "38",
                    "26 - 28",
                    "73 - 76",
                    "57 - 60",
                    "82 - 85"
                ],
                [
                    "XS",
                    "40",
                    "30 - 32",
                    "77 - 82",
                    "61 - 66",
                    "86 - 91"
                ],
                [
                    "S",
                    "42 - 44",
                    "34 - 36",
                    "83 - 88",
                    "67 - 72",
                    "92 - 97"
                ],
                [
                    "M",
                    "46 - 48",
                    "38 - 40",
                    "89 - 94",
                    "73 - 78",
                    "98 - 103"
                ],
                [
                    "L",
                    "48 - 50",
                    "42 - 44",
                    "95 - 101",
                    "79 - 85",
                    "104 - 110"
                ],
                [
                    "XL",
                    "52 - 54",
                    "46 - 48",
                    "102 - 109",
                    "86 - 94",
                    "111 - 117"
                ],
                [
                    "XXL",
                    "56 - 58",
                    "50 - 52",
                    "110 - 118",
                    "94 - 104",
                    "118 - 125"
                ]
            ],
            tableBodyDataNC: [
                [
                    "XXS",
                    "36 - 38",
                    "26 - 28",
                    "73 - 76",
                    "57 - 60",
                    "82 - 85"
                ],
                [
                    "XS",
                    "40 - 42",
                    "30 - 32",
                    "77 - 82",
                    "61 - 66",
                    "86 - 91"
                ],
                [
                    "S",
                    "44 - 46",
                    "34 - 36",
                    "83 - 88",
                    "67 - 72",
                    "92 - 97"
                ],
                [
                    "M",
                    "46 - 48",
                    "38 - 40",
                    "89 - 94",
                    "73 - 78",
                    "98 - 103"
                ],
                [
                    "L",
                    "50 - 52",
                    "42 - 44",
                    "95 - 101",
                    "79 - 85",
                    "104 - 110"
                ],
                [
                    "XL",
                    "52 - 54",
                    "46 - 48",
                    "102 - 109",
                    "86 - 94",
                    "111 - 117"
                ],
                [
                    "XXL",
                    "56 - 58",
                    "50 - 52",
                    "110 - 118",
                    "94 - 104",
                    "118 - 125"
                ]
            ],
        }
    },
    computed: {
        isDZData() {
            return this.sizeChart === "size_tops_woman_dz"
        },
        isNCData() {
            return this.sizeChart === "size_tops_woman_nc"
        },
        tableBodyData() {
            return this.isDZData ? this.tableBodyDataDZ : this.isNCData ? this.tableBodyDataNC : this.tableBodyDataMain;
        }
    }
}
