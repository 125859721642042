//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "../../../../common/ResponsiveImage";
import ClothesTable from "./ClothesTable.vue";
import SwimsuitsTable from "./SwimsuitsTable.vue";
import TrousersTable from "./TrousersTable.vue";

export default {
    name: "WomensTable",
    props: {
        sizeChart: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
            clothesImage: StaticImages.sizeTableClothesBlock,
            swimsuitsImage: StaticImages.sizeTableSwimsuitsBlock
        }
    },
    computed: {
        isWomenClothes() {
            return this.sizeChart === "size_tops_woman" || this.sizeChart === "size_tops_woman_dz" || this.sizeChart === "size_tops_woman_nc";
        },
        isWomenSwimsuits() {
            return this.sizeChart === "size_swimming_woman";
        },
        isWomenTrousers() {
            return this.sizeChart === "size_bottoms_woman";
        },
        tableTitle() {
            return this.isWomenSwimsuits ? this.$t('sizeTable.womenSwimsuits.title') :
                    this.isWomenClothes ? this.$t('sizeTable.womenClothes.title') :
                        this.$t('sizeTable.womenTrousers.title');
        },
        sizeTableClass() {
            return this.isWomenSwimsuits ? `${this.mainClass}__swimsuits` :
                    this.isWomenClothes ? `${this.mainClass}__clothes` :
                        `${this.mainClass}__trousers`;
        },
        imageSrc() {
            return this.isWomenSwimsuits ? this.swimsuitsImage : this.clothesImage;
        }
    },
    methods: {
        imageConfig(url, title = "", alt = "") {
            return {
                src: {
                    desktop: {
                        '1x': url,
                        '2x': url,
                    },
                    tablet: {
                        '1x': url,
                        '2x': url,
                    },
                    mobile: {
                        '1x': url,
                        '2x': url,
                    },
                },
                title: title,
                alt: alt,
            }
        },
    },
    components: {ResponsiveImage, ClothesTable, SwimsuitsTable, TrousersTable},

}
