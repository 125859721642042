//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "SwimsuitsTable",
    data: function () {
        return {
            tableHeadData: [
                this.$t('sizeTable.womenSwimsuits.table.header.title1'),
                this.$t('sizeTable.womenSwimsuits.table.header.title2'),
                this.$t('sizeTable.womenSwimsuits.table.header.title3'),
                this.$t('sizeTable.womenSwimsuits.table.header.title4'),
                this.$t('sizeTable.womenSwimsuits.table.header.title5'),
                this.$t('sizeTable.womenSwimsuits.table.header.title6'),
                this.$t('sizeTable.womenSwimsuits.table.header.title7')
            ],
            tableBodyData: [
                [
                    'XXS',
                    '34',
                    '26',
                    '73',
                    '57',
                    '82',
                    '148.75'
                ],
                [
                    'XXS',
                    '36',
                    '28',
                    '76',
                    '60',
                    '85',
                    '150'
                ],
                [
                    'XS',
                    '38',
                    '30',
                    '79',
                    '63',
                    '88',
                    '151.25'
                ],
                [
                    'XS',
                    '40',
                    '32',
                    '82',
                    '66',
                    '91',
                    '152.5'
                ],
                [
                    'S',
                    '42',
                    '34',
                    '85',
                    '69',
                    '94',
                    '154.13'
                ],
                [
                    'S',
                    '44',
                    '36',
                    '88',
                    '72',
                    '97',
                    '155.75'
                ],
                [
                    'M',
                    '46',
                    '38',
                    '91',
                    '75',
                    '100',
                    '157.25'
                ],
                [
                    'M',
                    '48',
                    '40',
                    '94',
                    '78',
                    '103',
                    '158.75'
                ],
                [
                    'L',
                    '50',
                    '42',
                    '97.5',
                    '81.5',
                    '106.5',
                    '160.13'
                ],
                [
                    'L',
                    '52',
                    '44',
                    '101',
                    '85',
                    '110',
                    '161.5'
                ],
                [
                    'XL',
                    '54',
                    '46',
                    '105',
                    '89,5',
                    '113.5',
                    '163.63'
                ],
                [
                    'XL',
                    '56',
                    '48',
                    '109',
                    '94',
                    '117',
                    '165.75'
                ],
                [
                    'XXL',
                    '58',
                    '50',
                    '113.5',
                    '99',
                    '121',
                    '168.38'
                ],
                [
                    'XXL',
                    '60',
                    '52',
                    '118',
                    '104',
                    '125',
                    '171'
                ]
            ]
        }
    }
}
